import { styled, css } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { Device } from '../../../../../play-core/styles/Device';
import { Fonts } from '../../../../../play-core/styles/Fonts';
import { ellipsis, getMultilineTruncation } from '../../../../../play-core/styles/style-helper';
import { Viewports } from '../../../../../play-core/styles/Viewports';
export const Title = styled.h3.withConfig({
  displayName: "MediaTeaserPartsstyles__Title",
  componentId: "sc-o4w8g6-0"
})(["", ";", ";color:", ";margin:0;", "{", ";", ";}"], Fonts.regular(18), getMultilineTruncation(2, 24), Colors.GREY_d2d2d2, Viewports.for('small'), Fonts.regular(16), getMultilineTruncation(2, 22));
export const Showtime = styled.span.withConfig({
  displayName: "MediaTeaserPartsstyles__Showtime",
  componentId: "sc-o4w8g6-1"
})(["", ";color:", ";flex-shrink:0;margin-right:12px;"], Fonts.bold(18), Colors.WHITE);
export const ShowTitle = styled.span.withConfig({
  displayName: "MediaTeaserPartsstyles__ShowTitle",
  componentId: "sc-o4w8g6-2"
})(["", ";color:", ";", ";max-width:100%;flex-shrink:1;"], Fonts.regular(18), Colors.GREY_969696, ellipsis);
export const ContentContainer = styled.div.withConfig({
  displayName: "MediaTeaserPartsstyles__ContentContainer",
  componentId: "sc-o4w8g6-3"
})(["display:flex;flex-direction:column;gap:4px;margin:12px 0 0 0;"]);
export const MetaData = styled.div.withConfig({
  displayName: "MediaTeaserPartsstyles__MetaData",
  componentId: "sc-o4w8g6-4"
})(["", ";color:", ";overflow:hidden;display:flex;", "{", ";}"], Fonts.regular(16), Colors.GREY_969696, Viewports.for('small'), Fonts.regular(14));
export const MetaDataText = styled.span.withConfig({
  displayName: "MediaTeaserPartsstyles__MetaDataText",
  componentId: "sc-o4w8g6-5"
})(["", ";max-width:100%;flex-shrink:", ";"], ellipsis, props => props.shrink ? '1' : '0');
export const extendedLinkStylesWithTitle = css(["", "{&:hover,&.focus-visible{", "{color:", ";}}}"], Device.mouse, Title, Colors.WHITE);
export const Description = styled.span.withConfig({
  displayName: "MediaTeaserPartsstyles__Description",
  componentId: "sc-o4w8g6-6"
})(["", ";line-height:24px;color:", ";", ";", "{display:none;}"], Fonts.regular(16), Colors.GREY_969696, getMultilineTruncation(3, 24), Viewports.for('small'));
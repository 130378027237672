import { useHydrated } from '../../../hooks/use-hydrated';
import { useVideoHistory } from '../../../providers/VideoHistoryProvider/VideoHistoryContext';
import { calculatePercentage } from '../../VideoPlayer/calculate-progress';
export const useVideoProgress = media => {
  const isHydrated = useHydrated();
  const {
    videoHistoryById
  } = useVideoHistory();
  if (isHydrated && media && videoHistoryById && videoHistoryById[media.id]) {
    return getProgressInPercentage(videoHistoryById[media.id], media);
  }
  return 0;
};
export const getProgressInPercentage = (progress, media) => progress.progressInPercentage || media && calculatePercentage(media.duration, progress.progressInSeconds) || 0;